import React from "react";
import { motion } from "framer-motion";

const HamburguerIcon = ({ isMenuOpen, setIsMenuOpen }) => {
  const variants = {
    isOpen: {
      top: 0,
      right: 0,
      x: 0,
      borderRadius: 0,
      width: 250,
      height: "100vh",
    },

    isClose: {
      x: 0,
    },

    hamburguerOpen: {
      top: 10,
      right: 10,
    },
    hamburguerClose: {},

    labelOpenOne: { scale: 0, y: 0, transition: { duration: 0.3 } },
    labelOpenTwo: { rotate: -45, y: 7, transition: { duration: 0.7 } },
    labelOpenThree: { rotate: 45, y: 0, transition: { duration: 0.7 } },

    labelClose: { y: 0 },

    navOpen: { x: 0 },

    itemOpen: {
      scale: 1,
    },

    itemClose: {
      scale: 0,
      transition: { delay: 0, duration: 0 },
    },
  };
  return (
    <motion.div
      className=" bg-black gap-1 flex-col absolute md:right-12 right-2 "
      transition={{ duration: 0.3, ease: "easeInOut" }}
      initial={{
        x: 110,
        borderRadius: "50%",
        width: 64,
        height: 64,
      }}
      animate={isMenuOpen ? "isOpen" : "isClose"}
      variants={variants}
    >
      {/* ACÁ ESTÁ NUESTRA HAMBURGUESA  */}
      <motion.div
        className="rounded-full w-16 h-16 absolute flex flex-col gap-1 justify-center items-center z-20"
        variants={variants}
        animate={isMenuOpen ? "hamburguerOpen" : "hamburguerClose"}
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
        }}
      >
        <motion.label
          className="bg-white w-8 h-1 rounded-md "
          transition={{ duration: 0.5, ease: "backOut", delay: 1 }}
          initial={{ y: -110 }}
          animate={isMenuOpen ? "labelOpenOne" : "labelClose"}
          variants={variants}
        ></motion.label>

        <motion.label
          className="bg-white w-8 h-1 rounded-md "
          transition={{ duration: 0.5, ease: "backOut", delay: 0.75 }}
          initial={{ y: -110 }}
          animate={isMenuOpen ? "labelOpenTwo" : "labelClose"}
          variants={variants}
        ></motion.label>

        <motion.label
          className="bg-white w-8 h-1 rounded-md"
          transition={{ duration: 0.5, ease: "backOut", delay: 0.5 }}
          initial={{ y: -110 }}
          animate={isMenuOpen ? "labelOpenThree" : "labelClose"}
          variants={variants}
        ></motion.label>
      </motion.div>

      {/* ACA LA NAVEGACIÓN */}
      <motion.div
        className="h-full gap-16 w-full z-10 flex flex-col justify-center items-center text-white text-4xl"
        variants={variants}
      >
        <motion.a
          href="/"
          transition={{ duration: 0.5, ease: "backOut", delay: 0.25 }}
          whileHover={"showLine"}
          initial={{ scale: 0 }}
          animate={isMenuOpen ? "itemOpen" : "itemClose"}
          className=" inline-block relative no-underline"
          variants={variants}
        >
          <motion.span
            className="absolute bottom-0 left-0 w-0 h-0.5 bg-white origin-left"
            variants={variants}
          />
          Principio
        </motion.a>

        <motion.a
          href="/menu"
          transition={{ duration: 0.5, ease: "backOut", delay: 0.5 }}
          whileHover={"showLine"}
          initial={{ scale: 0 }}
          animate={isMenuOpen ? "itemOpen" : "itemClose"}
          className=" inline-block relative no-underline "
          variants={variants}
        >
          <motion.span
            className="absolute bottom-0 left-0 w-0 h-0.5 bg-white origin-left"
            variants={variants}
          />
          Menu
        </motion.a>

        <motion.a
          href="/about"
          transition={{ duration: 0.5, ease: "backOut", delay: 0.75 }}
          whileHover={"showLine"}
          initial={{ scale: 0 }}
          animate={isMenuOpen ? "itemOpen" : "itemClose"}
          className=" inline-block relative no-underline"
          variants={variants}
        >
          <motion.span
            className="absolute bottom-0 left-0 w-0 h-0.5 bg-white origin-left"
            variants={variants}
          />
          Nosotros
        </motion.a>

        <motion.a
          href="/contact"
          transition={{ duration: 0.5, ease: "backOut", delay: 1 }}
          whileHover={"showLine"}
          initial={{ scale: 0 }}
          animate={isMenuOpen ? "itemOpen" : "itemClose"}
          className=" inline-block relative no-underline"
          variants={variants}
        >
          <motion.span
            className="absolute bottom-0 left-0 w-0 h-0.5 bg-white origin-left"
            variants={variants}
          />
          Contacto
        </motion.a>
      </motion.div>
    </motion.div>
  );
};

export default HamburguerIcon;
