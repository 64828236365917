import React, { useState } from "react";
import logo from "../../assets/icons/logo.png";
import { motion } from "framer-motion";
import useMediaQuery from "../Hook";
import Navigation from "./Navigation";
import HamburguerIcon from "./HamburguerIcon";
import { useLocation } from "react-router";

export default function TopBar({ isTopOfPage }) {
  const location = useLocation();
  const currentPath = location.pathname;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const backgroundTopBar = isTopOfPage ? "" : "bg-gray-300";

  return (
    <motion.header
      className={`${backgroundTopBar} z-50 w-full fixed top-0 py-2 h-20 flex flex-row`}
    >
      <motion.img
        src={logo}
        alt="El Ceibo logo"
        className="w-16 h-16 absolute left-2 md:left-12"
        transition={{ duration: 0.5, ease: "backOut" }}
        initial={{ x: -110 }}
        animate={{ x: 0, rotate: 360 }}
      />

      {isDesktop ? (
        <Navigation currentPath={currentPath} />
      ) : (
        <HamburguerIcon isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      )}
    </motion.header>
  );
}
