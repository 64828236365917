import React from "react";
import { motion } from "framer-motion";
import "../backgrounds.css";

const Navigation = ({ currentPath }) => {
  const variants = {
    showLine: {
      width: "100%",
    },
  };

  return (
    <motion.div className=" h-16 absolute flex flex-row justify-center items-center right-12 gap-10 text-3xl font-bold ">
      <motion.a
        href="/"
        transition={{ duration: 0.5, ease: "backOut", delay: 0.25 }}
        initial={{ y: -110 }}
        animate={{ y: 0 }}
        whileHover={"showLine"}
        className={`${
          currentPath === "/" ? "bg-image-nav-links shadow-text " : ""
        } inline-block relative no-underline text-white p-4`}
      >
        Principio
      </motion.a>

      <motion.a
        href="/menu"
        transition={{ duration: 0.5, ease: "backOut", delay: 0.5 }}
        initial={{ y: -110 }}
        animate={{ y: 0 }}
        whileHover={"showLine"}
        className={`${
          currentPath === "/menu" ? "bg-image-nav-links shadow-text " : ""
        } inline-block relative no-underline text-white p-4`}
      >
        Menu
      </motion.a>

      <motion.a
        href="/about"
        transition={{ duration: 0.5, ease: "backOut", delay: 0.75 }}
        initial={{ y: -110 }}
        animate={{ y: 0 }}
        whileHover={"showLine"}
        className={`${
          currentPath == "/about" ? "bg-image-nav-links shadow-text " : ""
        } inline-block relative no-underline text-white p-4`}
      >
        Nosotros
      </motion.a>

      <motion.a
        href="/contact"
        transition={{ duration: 0.5, ease: "backOut", delay: 1 }}
        initial={{ y: -110 }}
        animate={{ y: 0 }}
        whileHover={"showLine"}
        className={`${
          currentPath == "/contact" ? "bg-image-nav-links shadow-text " : ""
        } inline-block relative no-underline text-white p-4`}
      >
        Contacto
      </motion.a>
    </motion.div>
  );
};

export default Navigation;
