import React, { useState } from "react";
import flag from "../../assets/icons/argentina.png";
import logo from "../../assets/icons/logo.png";
import pin from "../../assets/icons/pin.png";
import bag from "../../assets/icons/bag.png";
import { motion } from "framer-motion";
import "../backgrounds.css";

export default function Home() {
  const [buttonHover, setButtonHover] = useState(false);

  const variants = {
    isHover: {
      scale: 1.1,
    },
    isNotHover: {
      scale: 1,
    },

    bagHover: {
      x: [-3, -5, -8, -10, 100],
    },
  };

  return (
    <motion.div
      className="home-background  overflow-hidden h-full  "
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.3 } }}
    >
      <div className="px-2 md:px-12 py-20   flex flex-col md:flex-row justify-center items-center w-full h-full bg-[rgb(0,0,0,0.6)]">
        {/* CONTIANER IMAGEN */}
        <motion.div
          className="h-fit w-full md:w-1/2 flex justify-center items-center my-drop  mb-8 md:mb-0 md:order-2"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.6 }}
        >
          <motion.img
            src={logo}
            alt="logo"
            className="w-[15rem] h-[15rem] md:w-[20rem] md:h-[20rem] "
          />
        </motion.div>

        {/* TEXTO CONTAINER */}
        <motion.div
          className="h-fit flex flex-col items-center justify-center  w-full md:w-1/2 text-white"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          {/* TITULO */}
          <motion.div className="flex flex-col items-center w-fit mb-4">
            <motion.h1 className="text-8xl font-bold">
              EL <span className="">CEIBO</span>
            </motion.h1>
            <motion.h2 className="text-4xl">Sabores argentinos</motion.h2>
          </motion.div>

          <motion.p
            className=" font-semibold text-xl w-full max-w-full text-center mb-8"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            Las comidas más ricas de nuestro país, puedes disfrutarlas gracias a
            nuestro local
          </motion.p>

          <motion.a
            className="bg-white text-black font-semibold text-3xl p-4 mb-8 rounded-lg flex flex-row overflow-hidden"
            href="/contact"
            animate={buttonHover ? "isHover" : "isNotHover"}
            variants={variants}
            onHoverStart={() => {
              setButtonHover(true);
            }}
            onHoverEnd={() => {
              setButtonHover(false);
            }}
          >
            Haz tu pedido{" "}
            <motion.img
              src={bag}
              className="w-[35px] ml-2"
              animate={buttonHover ? "bagHover" : ""}
              variants={variants}
            />
          </motion.a>

          <motion.div
            className="flex flex-row justify-center items-center"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5, delay: 0.7 }}
          >
            <motion.img
              src={pin}
              alt="pin"
              className="w-8 h-8"
              animate={{ y: [0, 5, 0, -5, 0] }}
              transition={{
                repeat: Infinity,
                duration: 1.5,
                ease: "linear",
              }}
            />
            <motion.p className="font-semibold text-xl w-full max-w-full text-center">
              C/ Compañia, 15 - Málaga
            </motion.p>
          </motion.div>

          <motion.img
            src={flag}
            alt="banderas"
            className="w-[12rem] h-[12rem]"
            initial={{ scale: 0 }}
            animate={{ scale: 1, rotate: 360 }}
            transition={{ duration: 0.5, delay: 0.9 }}
          />
        </motion.div>
      </div>
    </motion.div>
  );
}
