import React from "react";
import localPhoto from "../../assets/localPhoto.png";
import localPhotoTwo from "../../assets/localPhotoTwo.png";
import { motion } from "framer-motion";
import "../backgrounds.css";

export default function About() {
  return (
    <motion.div className="w-full h-full home-background">
      <motion.div className="px-2 md:px-12 py-20 w-full h-full justify-center items-center flex flex-col  text-white bg-[rgb(0,0,0,0.6)] ">
        <motion.div
          className="flex flex-col  mb-10 text-black"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
        >
          <motion.h3 className="text-5xl font-semibold text-white">
            NOSOTROS
          </motion.h3>
        </motion.div>

        {/* IMAGEN Y TEXTO */}
        <motion.div className="flex-col flex gap-6 w-full ">
          <motion.div className="flex flex-col md:flex-row justify-center items-center ">
            <motion.img
              src={localPhoto}
              alt="localphoto"
              className="w-[20rem] rounded-lg"
              initial={{ x: -100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
            />

            <motion.div className="md:w-1/2 max-w-[600px] flex flex-col md:ml-8 mt-8 md:mt-0 p-4 rounded-lg items-start gap-2 bg-[rgb(0,0,0,0.7)]">
              <motion.p className="text-[16px] font-semibold">
                Hace algunos años, una familia argentina se reunió en su país y
                decidieron dar un gran salto en sus vidas. Soñaban con llevar su
                cultura y gastronomía a otros lugares del mundo y encontraron en
                España, específicamente en Málaga, una oportunidad única para
                hacerlo realidad. Así nació El Ceibo, una empresa dedicada a
                compartir la auténtica comida argentina a los españoles y
                turistas de todo el mundo que visiten Málaga. A pesar de las
                dificultades que tuvieron que enfrentar, estos emprendedores no
                se detuvieron ante nada y pusieron todo su empeño en llevar a
                cabo su sueño.
              </motion.p>

              <motion.p className="text-[18px] font-semibold">
                Con mucho esfuerzo, dedicación y amor por su cultura, El Ceibo
                se convirtió rápidamente en uno de los locales de comida más
                populares de la ciudad. Su especialidad son las empanadas. Con
                más de 14 gustos diferentes, cada una de ellas preparada con
                ingredientes frescos y seleccionados cuidadosamente para obtener
                el mejor sabor. Además, cuentan con otras delicias como el
                tradicional chipá, dulces artesanales y tartas de diferentes
                sabores, que son preparados con la misma dedicación y esmero.
              </motion.p>

              <motion.p className="text-[18px] font-semibold">
                Pero El Ceibo no solo se dedica a la comida, también ofrecen una
                amplia variedad de productos argentinos como yerba mate, termos,
                bombillas, dulce de leche, mermeladas y vinos, para que todo
                aquel que desee pueda disfrutar de todo lo que Argentina tiene
                para ofrecer.
              </motion.p>
            </motion.div>
          </motion.div>

          <motion.div className="flex flex-col justify-center items-center ">
            <motion.div className="bg-[rgb(0,0,0,0.7)] rounded-lg mb-8 p-4 md:w-3/4 md:min-w-[600px]">
              <motion.p className="text-[18px] font-semibold">
                A pesar de estar lejos de su territorio natal, El Ceibo ha
                logrado transportar su cultura y compartir con los españoles una
                parte importante de su identidad. Y es que, para estos
                emprendedores, su empresa no es solo un negocio, sino un
                proyecto lleno de pasión y amor por lo que hacen.
              </motion.p>

              <motion.p className="text-[18px] font-semibold ">
                Si te encuentras en Málaga, no dudes en visitar El Ceibo en
                Calle Compañía, 15. Serás recibido con los brazos abiertos y con
                toda la calidez argentina que caracteriza a nuestro local. Ven y
                déjate seducir por los auténticos sabores argentinos.
              </motion.p>
            </motion.div>

            <motion.img
              src={localPhotoTwo}
              className="rounded-lg md:min-w-[600px]  md:w-3/4"
              initial={{ y: 100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
            />
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
}
