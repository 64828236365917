import React, { useState, useEffect } from "react";
import instagramIcon from "../../assets/icons/instagram.png";
import mapIcon from "../../assets/icons/map.png";
import teleIcon from "../../assets/icons/telephone.png";
import scheduleIcon from "../../assets/icons/schedule.png";
import tripadvisor from "../../assets/icons/tripadvisor.png";
import { motion } from "framer-motion";

export default function Contact() {
  const [time, setTime] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const currentHour = time.getHours();
    const currentDay = time.getDay();
    const isOpenToday = currentDay !== 1;

    if (isOpenToday) {
      const openingTime = currentDay === 1 ? 14 : 11;
      const closingTime = currentDay === 1 ? 22 : 23;

      setIsOpen(currentHour >= openingTime && currentHour < closingTime);
    } else {
      setIsOpen(false);
    }
  }, [time]);

  const contacting = [
    {
      icon: mapIcon,
      title: "Encuéntranos en",
      description: "C/ Compañia, 15 - Málaga",
      link: "https://www.google.com.ar/maps/place/Calle+Compañía,+15,+29008+Málaga,+España/@36.7213262,-4.4254369,17z/data=!3m1!4b1!4m6!3m5!1s0xd72f795fe47eb5b:0x554f894ea92ce555!8m2!3d36.7213262!4d-4.4232429!16s%2Fg%2F11b8v82sx7",
      cardDelay: 0.1,
    },
    {
      icon: instagramIcon,
      title: "Instagram",
      description: "elceibo.malaga",
      link: "https://instagram.com/elceibo.malaga?igshid=YmMyMTA2M2Y=",
      cardDelay: 0.25,
    },
    {
      icon: teleIcon,
      title: "llámanos a",
      description: "951 45 87 65",
      cardDelay: 0.5,
    },
    {
      icon: scheduleIcon,
      title: "Días disponibles",
      description: "Mar-Dom: 11AM - 23PM; Lun: 14PM - 22PM",
      ourDate: isOpen
        ? "nuestro local está abierto ahora mismo"
        : "nuestro local está cerrado ahora mismo",
      cardDelay: 0.75,
    },

    {
      icon: tripadvisor,
      title: "Tripadvisor",
      description: "Nuestra pagina de tripadvisor",
      link: "https://www.tripadvisor.com/Restaurant_Review-g187438-d25331791-Reviews-El_Ceibo_Sabores_Argentinos-Malaga_Costa_del_Sol_Province_of_Malaga_Andalucia.html",
      cardDelay: 1,
    },
  ];

  const contactDivs = contacting.map((el) => {
    return (
      <motion.a
        className={` flex flex-col h-42 w-[20rem] lg:w-[30rem] justify-center items-center bg-[rgb(0,0,0,0.7)] text-white rounded-lg`}
        href={el.link}
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5, delay: el.cardDelay }}
        target="_blank"
      >
        <img src={el.icon} alt="" className="w-20 h-20" />

        <div className="flex flex-col justify-center items-center">
          <h1 className="text-3xl">{el.title}</h1>
          <h3 className="text-2xl">{el.description}</h3>
          {el.ourDate ? (
            <h2
              className={
                isOpen ? "text-green-600 text-2xl" : "text-rose-600 text-2xl"
              }
            >
              {el.ourDate}
            </h2>
          ) : null}
        </div>
      </motion.a>
    );
  });

  return (
    <div className="home-background  overflow-hidden  h-full w-full flex flex-col justify-center items-center">
      <div className="bg-[rgb(0,0,0,0.6)] w-full h-full flex flex-col justify-center items-center py-20 px-2 lg:px-10">
        <motion.div
          className="flex flex-col items-center justify-center mb-4"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <motion.h3 className="text-4xl font-bold text-white">
            Contacto
          </motion.h3>
          <motion.p className=" text-3xl font-semibold text-white">
            ¿Necesitas ayuda? contáctanos
          </motion.p>
        </motion.div>

        <motion.div className="grid place-items-center lg:grid-cols-2 grid-cols-1 lg:grid-rows-2 grid-rows-1  gap-8">
          {contactDivs}
        </motion.div>
      </div>
    </div>
  );
}
