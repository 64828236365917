import React, { useState } from "react";
import { motion } from "framer-motion";
import "../backgrounds.css";
// import alfajor from "../../assets/foods/alfajor.jpg";
// import empanadas from "../../assets/foods/empanadas.jpg";
// import chipa from "../../assets/foods/chipa.jpg";
// import pastafrola from "../../assets/foods/pastafrola.png";
// import alfajorIcon from "../../assets/icons/alfajorIcon.png";
// import chipaIcon from "../../assets/icons/chipaIcon.png";
// import empanadaIcon from "../../assets/icons/empanadaIcon.png";
// import pastafrolaIcon from "../../assets/icons/pastafrolaIcon.png";
import menuIcon from "../../assets/icons/menuIcon.png";
import menuImg from "../../assets/menuImg.png";

export default function Menu() {
  //   const foods = [
  //     {
  //       name: "Empanada",
  //       icon: empanadaIcon,
  //       image: empanadas,
  //       description:
  //         "Una empanada es una fina masa de pan, masa quebrada u hojaldre rellena con una preparación salada o dulce cocida al horno o frita. El relleno puede incluir carne rojas o blancas, pescado y verduras",
  //       price: "2€",
  //       cardDelay: 0.1,
  //     },

  //     {
  //       name: "Empanada",
  //       icon: empanadaIcon,
  //       image: empanadas,
  //       description:
  //         "Una empanada es una fina masa de pan, masa quebrada u hojaldre rellena con una preparación salada o dulce cocida al horno o frita. El relleno puede incluir carne rojas o blancas, pescado y verduras",
  //       price: "2€",
  //       cardDelay: 0.2,
  //     },

  //     {
  //       name: "Empanada",
  //       icon: empanadaIcon,
  //       image: empanadas,
  //       description:
  //         "Una empanada es una fina masa de pan, masa quebrada u hojaldre rellena con una preparación salada o dulce cocida al horno o frita. El relleno puede incluir carne rojas o blancas, pescado y verduras",
  //       price: "2€",
  //       cardDelay: 0.3,
  //     },

  //     {
  //       name: "Chipa",
  //       icon: chipaIcon,
  //       image: chipa,
  //       description:
  //         "El chipá es un alimento a base de almidón de mandioca y queso, el cual es consumido en diferentes tiempos de comida.",
  //       price: "1€",
  //       cardDelay: 0.1,
  //     },

  //     {
  //       name: "Chipa",
  //       icon: chipaIcon,
  //       image: chipa,
  //       description:
  //         "El chipá es un alimento a base de almidón de mandioca y queso, el cual es consumido en diferentes tiempos de comida.",
  //       price: "1€",
  //       cardDelay: 0.2,
  //     },
  //     {
  //       name: "Chipa",
  //       icon: chipaIcon,
  //       image: chipa,
  //       description:
  //         "El chipá es un alimento a base de almidón de mandioca y queso, el cual es consumido en diferentes tiempos de comida.",
  //       price: "1€",
  //       cardDelay: 0.3,
  //     },

  //     {
  //       name: "pastafrola",
  //       icon: pastafrolaIcon,
  //       image: pastafrola,
  //       description:
  //         "consiste en una masa de tarta esponjosa, rellena con dulce de membrillo derretido y decorada con unas tiras de masa.",
  //       price: "2€",
  //       cardDelay: 0.1,
  //     },

  //     {
  //       name: "Alfajor",
  //       icon: alfajorIcon,
  //       image: alfajor,
  //       description:
  //         " constituido por dos o más galletitas, galletas o masa horneada, separadas entre sí por rellenos como mermeladas, jaleas, u otros dulces, pudiendo tener un baño o cobertura exterior",
  //       price: "1€",
  //       cardDelay: 0.2,
  //     },

  //     {
  //       name: "Alfajor",
  //       icon: alfajorIcon,
  //       image: alfajor,
  //       description:
  //         " constituido por dos o más galletitas, galletas o masa horneada, separadas entre sí por rellenos como mermeladas, jaleas, u otros dulces, pudiendo tener un baño o cobertura exterior",
  //       price: "1€",
  //       cardDelay: 0.3,
  //     },
  //   ];

  //   const groups = [];
  //   const groupSize = 3;

  //   for (let i = 0; i < foods.length; i += groupSize) {
  //     groups.push(foods.slice(i, i + groupSize));
  //   }

  return (
    <motion.div
      className="home-background overflow-hidden h-screen w-full"
      initial={{ width: 0 }}
      animate={{ width: "100%" }}
      exit={{ x: window.innerWidth, transition: { duration: 0.3 } }}
    >
      <motion.div className="px-2 md:px-12 md:pt-20   flex flex-col  justify-center items-center w-full h-full bg-[rgb(0,0,0,0.6)]">
        <motion.h1
          className="text-5xl font-semibold pb-6 flex flex-row items-center text-white"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
        >
          Nuestro menú <motion.img src={menuIcon} className="w-10 h-10 ml-2" />
        </motion.h1>

        <motion.div
          class=" md:w-[45rem] md:h-[45rem]  overflow-hidden cursor-zoom-in"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <img
            src={menuImg}
            alt="menu"
            class="w-full h-auto transition-transform duration-300 transform hover:scale-125 hover:cursor-zoom-in"
          />
        </motion.div>

        {/* <motion.div className="grid grid-cols-3 gap-12">
          {groups.map((group, groupIndex) => (
            <React.Fragment key={groupIndex}>
              {group.map((food, index) => (
                <motion.div
                  className={`bg-img-card-${food.name.toLocaleLowerCase()} hover-effect w-[250px] h-[370px]  shadow-lg shadow-black flex flex-col rounded-md relative`}
                  key={index}
                  initial={{ scale: 0 }}
                  whileInView={{ scale: 1 }}
                  transition={{
                    duration: 0.3,
                    delay: food.cardDelay,
                    ease: "anticipate",
                  }}
                >
                  <motion.div className="bg-gradient-rainbow w-full flex-grow px-6 rounded-b-md">
                    <motion.h3 className="text-[27px] flex flex-row font-bold py-4 text-white">
                      {food.name}{" "}
                      <img src={food.icon} className="w-9 h-9 ml-2" />
                    </motion.h3>

                    <motion.div className="bg-gray-300 h-[2px] w-full"></motion.div>

                    <motion.p className="food-desc text-white font-semibold text-[20px] w-full py-4">
                      {food.description}
                    </motion.p>
                  </motion.div>
                </motion.div>
              ))}
            </React.Fragment>
          ))}
        </motion.div> */}
      </motion.div>
    </motion.div>
  );
}
