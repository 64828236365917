import TopBar from "./components/topBar/TopBar";
import { useState, useEffect } from "react";
import { AnimatedRoutes } from "./AnimatedRoutes";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  const [isTopOfPage, setIsTopOfPage] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsTopOfPage(true);
      }
      if (window.scrollY !== 0) setIsTopOfPage(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Router>
        <TopBar isTopOfPage={isTopOfPage} />
        <AnimatedRoutes />
      </Router>
    </>
  );
}

export default App;
